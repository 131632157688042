
import Vue from 'vue';
import Datepicker from 'vue-moment-datepicker';
import { LatLng } from 'leaflet';
import moment from 'moment-timezone';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import {
  Pems,
  ServiceLog, ServiceLogPhoto,
  Unit,
  UnitBaseline,
  ServiceLogReason,
} from '@/types';
import units from '@/services/units';
import Analytics from '@/types/analytics';
import ProfileService from '@/services/profile';
import organizations from '@/services/organizations';
import PreferencesService from '@/services/preferences';
import Chart from '../components/Chart.vue';
import UnitInstallation from '../components/UnitInstallation.vue';

export default Vue.extend({
  name: 'Units',
  components: {
    Datepicker,
    Chart,
    UnitInstallation,
    DatePicker,
  },
  data() {
    return {
      isBusy: false,
      showAlert: true,
      readonlyMode: false,
      fields: [
        {
          label: 'Tag',
          key: 'h2GenTag',
          sortable: true,
        },
        {
          key: 'model',
          sortable: false,
        },
        // {
        //   key: 'manufacturer',
        //   sortable: false,
        // },
        {
          key: 'date_stamp',
          sortable: false,
        },
        {
          key: 'vin',
          sortable: false,
        },
        {
          key: 'engine_manufacturer',
          sortable: false,
        },
        {
          label: 'Fleet ID',
          key: 'fleet_id',
          sortable: false,
        },
        {
          key: 'organization',
          sortable: false,
        },
        {
          key: 'status_code',
          sortable: false,
        },
        {
          key: 'status_text',
          sortable: false,
        },
        // {
        //   key: 'date_created',
        //   label: 'Created',
        //   sortable: true,
        // },
        {
          key: 'info',
          sortable: false,
        },
        // {
        //   key: 'ycs',
        //   sortable: false,
        // },
      ],
      allItems: [] as Unit[],
      items: [] as Unit[],
      customers: [] as any[],
      unitSearch: '' as string,
      filterUnitsActive: true,
      filterUnitStatus: null as string | null,
      unitInstallationModalVisible: false,
      unitDetailsModalVisible: false,
      selectedUnitTag: '',
      unitDetailsEntity: { h2GenUnitInfo: {} } as Unit,
      unitDetailsEntityRef: { h2GenUnitInfo: {} } as Unit,
      unitAnalyticsMonthOffset: 0,
      unitHasNoReactorData: true,
      unitAnalytics: {} as Analytics,
      unitHasNoAnalytics: true,
      saveUnitDisabled: false,
      showButtons: false,
      unitsMetric: true,
      baselineMonthBool: false,
      baselineMonth: null as string | null,
      baselineMonthDate: null as string | null,
      unitsUS: false,
      unitsImperial: false,
      unitsUsOrImperial: false,
      unitAnalytics3MonthsAgo: moment()
        .subtract(3, 'months')
        .format('MMM'),
      unitAnalytics2MonthsAgo: moment()
        .subtract(2, 'months')
        .format('MMM'),
      unitAnalytics1MonthAgo: moment()
        .subtract(1, 'months')
        .format('MMM'),
      unitAnalyticsLoading: false,
      fuelSavingsChartData: {},
      fuelEfficiencyChartData: {},
      locationHistoryModeDay: true,
      locationHistoryModeInterval: false,
      historyDatesIntervalSelectionPopover: false,
      historyDatesIntervalStart: new Date(),
      historyDatesIntervalStop: new Date(),
      unitLocationDisabled: true,
      map: {
        unit: { h2GenUnitInfo: {} } as Unit,
        live: true,
        history: false,
        historyStart: null as Date | null,
        historyStop: null as Date | null,
        zoom: 13,
        center: new LatLng(47.41322, -1.219482),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        currentZoom: 11.5,
        currentCenter: new LatLng(47.41322, -1.219482),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
        },
        showMap: true,
        markers: [] as any[],
      },
      unitPhotos: [] as any[],
      unitPhotosNames: [
        'Truck Front',
        'Geotab SN',
        'Unit Mounting',
        'Battery Connections',
        'Gas Line Intake',
      ],
      unitBaseline: {} as UnitBaseline,
      startDateBas: '',
      endDateBas: '',
      pemsTests: [] as Pems[],
      showPemsTestModal: false,
      pemsTest: null as Pems | null,
      pemsTestsFields: [
        {
          label: 'Timestamp',
          key: 'testTimestamp',
          sortable: true,
        },
        {
          label: 'RPM',
          key: 'rpm',
          sortable: true,
        },
        {
          label: 'CO (ppm)',
          key: 'co',
          sortable: true,
        },
        {
          label: 'CO₂ (%)',
          key: 'co2',
          sortable: true,
        },
        {
          label: 'Exhaust Temp (°F)',
          key: 'exhaustTemperature',
          sortable: true,
        },
        {
          label: 'NOX (ppm)',
          key: 'nox',
          sortable: true,
        },
        {
          key: 'info',
          sortable: false,
        },
        {
          key: 'delete',
          sortable: false,
        },
      ],
      csv_data: [] as any[],
      csv_filename: 'empty.csv',
      allowedEditUnit: true,
      allowedInstallUnit: true,
      serviceLogs: [] as ServiceLog[],
      serviceLogReasons: [] as any[],
      showServiceLogModal: false,
      serviceLog: null as ServiceLog | null,
      serviceLogPhotos: [] as ServiceLogPhoto[],
      serviceLogsFields: [
        {
          label: 'Timestamp',
          key: 'timestamp',
          sortable: true,
        },
        {
          label: 'Location',
          key: 'location',
          sortable: true,
        },
        {
          label: 'Reason',
          key: 'reason',
          sortable: true,
        },
        {
          label: 'Resolved',
          key: 'resolved',
          sortable: true,
        },
        {
          key: 'info',
          sortable: false,
        },
      ],

      selectedReason: false,
      showInfoReason1: false,
      showInfoReason2: false,
      reasonDetails1: '',
      reasonDetails2: '',
      reasonDetailsPlaceHolder1: '',
      reasonDetailsPlaceHolder2: '',
      replaceSIM: false,
      replaceECU: false,
      replaceUnit: false,
      newTagVisible: false,
      newImeiVisible: false,
      newSimVisible: false,
      oldTagVisible: false,
      oldImeiVisible: false,
      oldSimVisible: false,
      replacementReadonly: false,
      simActivationProfiles: [] as any[],
      selectedSubUnit: {} as any,
      reactorCurrentStatus: '',
      unitStatus: '',
      selectedImageFile: null,
      lastPhotoId: 0,
    };
  },
  methods: {
    async init(): Promise<void> {
      try {
        const userType = ProfileService.getUserType();
        this.readonlyMode = userType === null || userType > 60;
        const u = await units.readAll(true);
        if (u) {
          for (let i = 0; i < u.length; i++) {
            const x = u[i];
            if (x.telematicsId) {
              continue;
            }
            if (x.h2GenUnitInfo && x.h2GenUnitInfo.telematicsId) {
              x.telematicsId = x.h2GenUnitInfo.telematicsId;
            }
          }
        }
        this.allItems = u;
        this.items.splice(0, this.items.length);
        u.sort((u1, u2) => u1.h2GenTag.localeCompare(u2.h2GenTag));
        if (this.$route.query.statusFilter) {
          const f = this.$route.query.statusFilter;
          if (f === 'running'
            || f === 'shutdown'
            || f === 'service'
            || f === 'water'
            || f === 'installed') {
            this.filterUnitStatus = f;
          }
        }
        this.filterUnits();
        const orgs = await organizations.readAll();
        orgs.forEach((o) => {
          switch (o.type) {
            case 30:
              this.customers.push({
                value: o.id,
                text: `DEALER - ${o.name}`,
                org: o,
              });
              break;
            case 40:
              this.customers.push({
                value: o.id,
                text: `CUSTOMER - ${o.name}`,
                org: o,
              });
              break;
            default:
              break;
          }
        });

        if (userType) {
          // if (userType === 60 || userType === 70) {
          //   this.allowedEditUnit = false;
          // } else {
          //   this.allowedEditUnit = true;
          // }
          // if (userType === 60 || userType === 70 || userType === 30) {
          //   this.allowedInstallUnit = false;
          // } else {
          //   this.allowedInstallUnit = true;
          // }

          // installer
          if (userType === 20) {
            this.filterUnitsActive = false;
            this.filterUnits();
          }
        }
        const simActivationProfiles = await units.getSimActivationProfiles();
        simActivationProfiles.forEach((ap) => {
          this.simActivationProfiles.push({
            value: ap.activationProfileId,
            text: ap.activationProfileName,
          });
        });

        const serviceLR = await units.readReasonsServiceLogs('0');
        serviceLR.forEach((r) => {
          this.serviceLogReasons.push({
            value: r.id,
            text: r.name,
          });
        });
      } catch {
        // ignore for now...
      }
    },
    addUnit(): void {
      this.unitInstallationModalVisible = true;
    },
    async showUnitDetails(unit: Unit, isSubUnit: boolean): Promise<void> {
      if (unit.reactorData !== undefined && unit.reactorData.timestamp !== undefined) {
        const dateLastTransmitted = moment(unit.reactorData.timestamp);
        const today = moment(new Date());
        const dayDifference = today.diff(dateLastTransmitted, 'day');
        if (dayDifference > 60) {
          this.reactorCurrentStatus = 'Inactive';
        } else {
          this.reactorCurrentStatus = unit.reactorData.reactorOn ? 'ON' : 'OFF';
        }
      }

      this.unitStatus = unit.h2GenLastStatusFullText.replace(/\(.*?\)/g, '');
      this.selectedUnitTag = unit.h2GenTag;
      this.isBusy = true;
      const userUnits = PreferencesService.getUnits();
      this.unitsMetric = userUnits === 'metric';
      this.unitsUS = userUnits === 'us';
      this.unitsImperial = userUnits === 'imperial';
      this.unitsUsOrImperial = userUnits === 'us' || userUnits === 'imperial';
      this.unitDetailsEntity = { ...unit };
      if (!isSubUnit && this.unitDetailsEntity.isVirtual && this.unitDetailsEntity.subUnits) {
        this.unitDetailsEntityRef = { ...unit };
        this.unitDetailsEntityRef.subUnitsList = [];
        this.unitDetailsEntity.subUnits.forEach(su => {
          this.unitDetailsEntityRef.subUnitsList.push({
            value: su,
            text: `${su.h2GenTag} #${su.subUnitId}`,
          });
        });
      } else if (!isSubUnit) {
        this.unitDetailsEntityRef = { ...unit };
      }
      this.unitDetailsEntity.h2GenUnitInfo = { ...unit.h2GenUnitInfo };
      this.unitDetailsModalVisible = true;
      this.unitHasNoReactorData = !unit.reactorData;
      this.unitHasNoAnalytics = true;
      this.map.live = true;
      this.map.history = false;
      this.map.historyStart = null;
      this.map.historyStop = null;
      this.map.markers.splice(0, this.map.markers.length);
      this.showUnitLocation(unit);
      await this.fetchUnitAnalytics(unit);
      this.isBusy = false;
      // const startDateBaseline = moment(this.unitBaseline.startDate);
      // this.startDateBas = startDateBaseline.format('YYYY-MM-DD');
      // const endDateBaseline = moment(this.unitBaseline.reportingBaselineDate);
      // this.endDateBas = endDateBaseline.format('YYYY-MM-DD');
      // this.efficiencyCalcBasedOnHours = this.unitBaseline.efficiencyCalculationBasedOnHours;
      // this.efficiencyCalcBasedOnDistance = !this.unitBaseline.efficiencyCalculationBasedOnHours;
      const installationDateObj = moment(this.unitDetailsEntity.h2GenUnitInfo.installationDate);
      const installationDateString = installationDateObj.format('YYYY-MM-DD');
      if (installationDateString === '0001-01-01') {
        this.unitDetailsEntity.h2GenUnitInfo.installationDate = new Date();
      }

      if (this.unitDetailsEntity.isVirtual && this.unitDetailsEntity.subUnits) {
        if (this.unitDetailsEntity.subUnits.length > 0) {
          this.selectedSubUnit = this.unitDetailsEntity.subUnits[0];
          this.subUnitChanged(this.unitDetailsEntity.subUnits[0]);
        }
      }
    },
    subUnitChanged(subUnit: Unit): void {
      this.showUnitDetails(subUnit, true);
    },
    showUnitLocation(unit: Unit): void {
      /* Just for Innoveysa for now -> (175 - Innoveysa and Customer from Innoveysa) */
      const profile = ProfileService.getStoredProfile();
      if (profile?.organization?.id === 175 || profile?.organization?.parentId === 175) {
        this.unitLocationDisabled = true;
        return;
      }
      /**/
      if (!unit.reactorData) {
        this.unitLocationDisabled = true;
        return;
      }
      this.unitLocationDisabled = false;
      this.map.unit = unit;
      const loc = new LatLng(unit.reactorData.gpsLatitude, unit.reactorData.gpsLongitude);
      this.map.zoom = 12;
      this.map.center = loc;
      this.map.markers.push({
        id: 0,
        position: loc,
        tooltipText: unit.h2GenTag,
      });
      this.map.showMap = true;
      this.$nextTick(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.map.zoom = 13;
        }, 250);
      });
    },
    setMapModeToLive(): void {
      this.map.live = true;
      this.map.history = false;
      const { unit } = this.map;
      this.map.markers.splice(0, this.map.markers.length);
      this.showUnitLocation(unit);
    },
    async setMapModeToHistory(): Promise<void> {
      this.isBusy = true;
      this.map.markers.splice(0, this.map.markers.length);
      const list = await units.locations(this.map.unit.h2GenTag,
        this.map.historyStart,
        this.map.historyStop);
      this.map.historyStart = list.startTimestamp;
      this.map.historyStop = list.stopTimestamp;
      this.map.live = false;
      this.map.history = true;
      if (list.locations.length === 0) {
        this.$bvToast.toast('No location data for the dates specified', {
          autoHideDelay: 3000,
          appendToast: false,
          variant: 'warning',
          solid: true,
        });
        this.isBusy = false;
        return;
      }
      this.map.zoom = 12;
      const fl = list.locations[0];
      this.map.center = new LatLng(fl.latitude, fl.longitude);
      list.locations.forEach((p) => {
        const loc = new LatLng(p.latitude, p.longitude);
        this.map.markers.push({
          id: p.timestamp,
          position: loc,
          tooltipText: p.timestamp,
        });
      });
      this.map.showMap = true;
      this.isBusy = false;
      this.$nextTick(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.map.zoom = 13;
        }, 250);
      });
    },
    getFormattedDate(timestamp:Date) {
      const date = new Date(timestamp);
      const localTimezone = moment.tz.guess();
      const time = moment.tz(date, localTimezone);
      const formattedDate = moment(time).format('MMMM Do YYYY, h:mm:ss a');
      return formattedDate;
    },
    setMapHistoryToInterval() {
      this.historyDatesIntervalStart = this.map.historyStart as Date;
      this.historyDatesIntervalStop = this.map.historyStop as Date;
      this.locationHistoryModeDay = false;
      this.locationHistoryModeInterval = true;
      this.historyDatesIntervalSelectionPopover = true;
    },
    async setMapHistoryToDay() {
      this.map.historyStart = new Date(this.map.historyStart as Date);
      const d = new Date(this.map.historyStart as Date);
      d.setDate(d.getDate() + 1);
      this.map.historyStop = d;
      this.historyDatesIntervalSelectionPopover = false;
      this.locationHistoryModeDay = true;
      this.locationHistoryModeInterval = false;

      await this.setMapModeToHistory();
    },
    toggleMapHistoryIntervalPopover() {
      this.historyDatesIntervalSelectionPopover = !this.historyDatesIntervalSelectionPopover;
    },
    async setMapHistoryToPreviousDay() {
      let d = new Date(this.map.historyStart as Date);
      d.setDate(d.getDate() - 1);
      this.map.historyStart = d;
      d = new Date(this.map.historyStop as Date);
      d.setDate(d.getDate() - 1);
      this.map.historyStop = d;

      await this.setMapModeToHistory();
    },
    async setMapHistoryToNextDay() {
      let d = new Date(this.map.historyStart as Date);
      d.setDate(d.getDate() + 1);
      this.map.historyStart = d;
      d = new Date(this.map.historyStop as Date);
      d.setDate(d.getDate() + 1);
      this.map.historyStop = d;

      await this.setMapModeToHistory();
    },
    async requestMapHistory() {
      let d = new Date(this.historyDatesIntervalStart as Date);
      this.map.historyStart = d;
      d = new Date(this.historyDatesIntervalStop as Date);
      this.map.historyStop = d;
      await this.setMapModeToHistory();
    },
    historyDatesIntervalStartChanged(date: Date | null) {
      this.historyDatesIntervalStart = date as Date;
    },
    historyDatesIntervalStopChanged(date: Date | null) {
      this.historyDatesIntervalStop = date as Date;
    },
    async fetchUnitAnalytics(unit: Unit): Promise<void> {
      this.isBusy = true;
      this.unitAnalyticsLoading = true;
      const analytics = await units.analytics(unit.h2GenTag, this.unitAnalyticsMonthOffset);
      const bMonth = analytics
        .getLastMonthsHasBaselineDate(analytics.baselineDate, this.unitAnalyticsMonthOffset);
      this.baselineMonthBool = bMonth;
      const baselineMoment = moment(analytics.baselineDate);
      this.baselineMonth = baselineMoment.format('MMM');
      this.baselineMonthDate = baselineMoment.format('DD MMM YYYY');
      Vue.set(this, 'unitAnalytics', analytics);
      analytics.updateChartData(PreferencesService.getUnits(), this.unitAnalyticsMonthOffset);
      Vue.set(this, 'fuelSavingsChartData', analytics.fuelSavingsChartData);
      Vue.set(this, 'fuelEfficiencyChartData', analytics.fuelEfficiencyChartData);
      if (this.unitAnalytics && this.unitAnalytics.baselineDate) {
        this.unitHasNoAnalytics = false;
      }
      this.unitAnalyticsLoading = false;
      this.isBusy = false;
    },
    async updateInfo() {
      try {
        this.showButtons = true;
      } catch (error) {
        // ignore for now
      }
    },
    async updateReact() {
      try {
        this.showButtons = false;
      } catch (error) {
        // ignore for now
      }
    },
    async updateBaseline() {
      try {
        this.unitBaseline = await units.readBaseline(this.unitDetailsEntity.h2GenTag);
        // const momentObj = moment(this.unitBaseline.startDate);
        // const startDateString = momentObj.format('YYYY-MM-DD');

        // const momentObj2 = moment(this.unitBaseline.reportingBaselineDate);
        // const endDateString = momentObj2.format('YYYY-MM-DD');

        // this.unitBaseline.startBaselineDate = startDateString;
        // this.unitBaseline.endBaselineDate = endDateString;

        // this.unitBaseline.startBaselineDate = this.unitBaseline.startDate;
        // this.unitBaseline.endBaselineDate = this.unitBaseline.reportingBaselineDate;
        if (this.unitBaseline.closed) {
          const effCalcBasedOnHours = this.unitBaseline.efficiencyCalculationBasedOnHours;
          this.unitBaseline.efficiencyCalculationBasedOnHours = effCalcBasedOnHours;
          this.unitBaseline.efficiencyCalculationBasedOnDistance = !effCalcBasedOnHours;
        } else {
          this.unitBaseline.efficiencyCalculationBasedOnHours = false;
          this.unitBaseline.efficiencyCalculationBasedOnDistance = false;
        }
        this.showButtons = false;
      } catch (error) {
        // ignore for now
      }
    },
    updateUnitLocation(): void {
      this.$nextTick(() => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.map.zoom = 13;
        }, 250);
      });
      this.showButtons = false;
    },
    async saveReportingBaseline() {
      try {
        await units.updateBaseline(this.unitDetailsEntity.h2GenTag, this.unitBaseline);

        this.$bvToast.toast('Reporting Baseline saved', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
        });
      } catch {
        // ignore for now
      }
    },
    async updatePemsTests() {
      try {
        const base64Header = 'data:image/jpeg;base64,';
        const tests = await units.readPems(this.unitDetailsEntity.h2GenTag);
        this.pemsTests.length = 0;
        tests.forEach(t => {
          t.testTimestamp = new Date(t.testTimestamp);
          if (t.photo) {
            t.photo = `${base64Header}${t.photo}`;
          }
          this.pemsTests.push(t);
        });
        this.showButtons = false;
      } catch {
        // ignore for now
      }
    },
    async updateAnalytics() {
      try {
        this.showButtons = false;
      } catch (error) {
        // ignore for now
      }
    },
    async showPemsDetails(pems: Pems) {
      this.pemsTest = { ...pems };
      this.showPemsTestModal = true;
    },
    async addPemsTest() {
      this.pemsTest = {
        id: 0,
        testTimestamp: new Date(),
        unitTag: this.unitDetailsEntity.h2GenTag,
        rpm: '0',
        co: '0',
        co2: '0',
        exhaustTemperature: '0',
        nox: '0',
        photo: '',
      };
      this.showPemsTestModal = true;
    },
    async createPemsTest() {
      try {
        if (this.pemsTest === null) {
          return;
        }

        const base64Header = 'data:image/jpeg;base64,';
        if (this.pemsTest.photo) {
          this.pemsTest.photo = this.pemsTest.photo.replace(base64Header, '');
        }
        const test = await units.createPems(this.unitDetailsEntity.h2GenTag, this.pemsTest);

        if (test.photo) {
          test.photo = `${base64Header}${test.photo}`;
        }
        test.testTimestamp = new Date(test.testTimestamp);
        this.pemsTests.push(test);
        this.showPemsTestModal = false;
      } catch {
        // ignore for now
      }
    },
    async updatePemsTest() {
      try {
        if (this.pemsTest === null) {
          return;
        }
        const id = this.pemsTest.id;
        const base64Header = 'data:image/jpeg;base64,';

        if (this.pemsTest.photo) {
          this.pemsTest.photo = this.pemsTest.photo.replace(base64Header, '');
        }
        await units.updatePems(this.unitDetailsEntity.h2GenTag, this.pemsTest);
        if (this.pemsTest.photo) {
          this.pemsTest.photo = `${base64Header}${this.pemsTest.photo}`;
        }
        const index = this.pemsTests.findIndex(t => t.id === id);
        this.pemsTests.splice(index, 1);
        this.pemsTests.push(this.pemsTest);
        this.showPemsTestModal = false;
      } catch {
        // ignore for now
      }
    },
    async deletePemsTest(pemsTest: Pems) {
      try {
        const id = pemsTest.id;
        await units.deletePems(this.unitDetailsEntity.h2GenTag, id);
        const index = this.pemsTests.findIndex(t => t.id === id);
        this.pemsTests.splice(index, 1);
      } catch {
        // ignore for now
      }
    },
    addPemsTestPhoto(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.pemsTest) {
          this.pemsTest.photo = e.target?.result as string;
          this.$forceUpdate();
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    removePemsTestPhoto(): void {
      if (this.pemsTest) {
        this.pemsTest.photo = '';
        this.$forceUpdate();
      }
    },
    openPemsTestImage(): void {
      if (this.pemsTest) {
        const image = new Image();
        image.src = this.pemsTest.photo;
        const w = window.open();
        if (w) {
          w.document.write(image.outerHTML);
        }
      }
    },
    async loadServiceLogs(): Promise<void> {
      try {
        const logs = await units.readServiceLogs(this.unitDetailsEntity.h2GenTag);
        this.serviceLogs.length = 0;
        logs.forEach(l => {
          l.timestamp = new Date(l.timestamp);
          this.serviceLogs.push(l);
        });
        this.showButtons = false;
      } catch {
        // ignore for now
      }
    },
    async showServiceLogDetails(sl: ServiceLog): Promise<void> {
      this.serviceLog = { ...sl };
      this.replaceUnit = false;
      this.replaceECU = false;
      this.replaceSIM = false;
      this.selectedReason = false;
      this.showInfoReason1 = false;
      this.showInfoReason2 = false;
      this.reasonDetails1 = '';
      this.reasonDetails2 = '';
      this.reasonDetailsPlaceHolder1 = '';
      this.reasonDetailsPlaceHolder2 = '';
      this.newTagVisible = false;
      this.newImeiVisible = false;
      this.newSimVisible = false;
      this.oldTagVisible = false;
      this.oldImeiVisible = false;
      this.oldSimVisible = false;
      this.replacementReadonly = false;
      if (this.serviceLog.h2GenUnitReplacement) {
        if (this.serviceLog.h2GenUnitReplacement.replacementType === 10) {
          this.replaceUnit = true;
          this.oldTagVisible = true;
          this.showInfoReason1 = true;
          this.showInfoReason2 = true;
          this.newSimVisible = false;
          this.reasonDetailsPlaceHolder1 = 'Old Tag';
          this.reasonDetailsPlaceHolder2 = 'New Tag';
          this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousTag ?? '';
          this.reasonDetails2 = this.serviceLog.h2GenUnitReplacement.newTag ?? '';
        } else if (this.serviceLog.h2GenUnitReplacement.replacementType === 20) {
          this.replaceECU = true;
          this.oldImeiVisible = true;
          this.showInfoReason1 = true;
          this.showInfoReason2 = true;
          this.newSimVisible = false;
          this.reasonDetailsPlaceHolder1 = 'Old IMEI';
          this.reasonDetailsPlaceHolder2 = 'New IMEI';
          this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousImei ?? '';
          this.reasonDetails2 = this.serviceLog.h2GenUnitReplacement.newImei ?? '';
        } else if (this.serviceLog.h2GenUnitReplacement.replacementType === 25) {
          this.replaceECU = true;
          this.replaceSIM = true;
          this.oldImeiVisible = true;
          this.oldSimVisible = true;
          this.showInfoReason1 = true;
          this.showInfoReason2 = true;
          this.newSimVisible = false;
          this.reasonDetailsPlaceHolder1 = 'Old IMEI';
          this.reasonDetailsPlaceHolder2 = 'New IMEI';
          this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousImei ?? '';
          this.reasonDetails2 = this.serviceLog.h2GenUnitReplacement.newImei ?? '';
        } else if (this.serviceLog.h2GenUnitReplacement.replacementType === 30) {
          this.replaceSIM = true;
          this.oldSimVisible = true;
          this.showInfoReason1 = true;
          this.showInfoReason2 = true;
          this.reasonDetailsPlaceHolder1 = 'Old SIM';
          this.reasonDetailsPlaceHolder2 = 'New SIM';
          this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousIccid ?? '';
          this.reasonDetails2 = this.serviceLog.h2GenUnitReplacement.newIccid ?? '';
          this.newSimVisible = true;
        }
        this.replacementReadonly = true;
      }
      if (this.serviceLog.reasonId === 7) {
        this.showInfoReason1 = false;
        this.showInfoReason2 = true;
        this.reasonDetailsPlaceHolder1 = '';
        this.reasonDetailsPlaceHolder2 = 'Specify';
        this.reasonDetails1 = '';
        this.reasonDetails2 = this.serviceLog.reason ?? '';
      }
      await this.updateServiceLogPhotos();
      this.showServiceLogModal = true;
    },
    async updateServiceLogPhotos(): Promise<void> {
      // const base64Header = 'data:image/jpeg;base64,';

      if (this.serviceLog) {
        const sl = this.serviceLog;
        this.serviceLogPhotos.splice(0, this.serviceLogPhotos.length);
        if (sl.photos) {
          for (let i = 0; i < sl.photos.length; i += 1) {
            const p = sl.photos[i];
            const photo = await units.getServiceLogPhoto(this.unitDetailsEntity.h2GenTag, sl.id, p);
            photo.id = p;
            // photo.data = `${base64Header}${photo.data}`;
            photo.data = `${photo.data}`;
            this.serviceLogPhotos.push(photo);
          }
        }
      }
    },
    addServiceLog(): void {
      // le
      this.serviceLog = {
        id: 0,
        timestamp: new Date(),
        h2GenUnitTag: this.unitDetailsEntity.h2GenTag,
        location: '',
        reason: '',
        reasonId: 0,
        technician: '',
        resolved: false,
        resolution: '',
        photos: [],
        h2GenUnitReplacement: null,
        serviceLogReason: null,
      };
      this.serviceLogPhotos = [];
      this.lastPhotoId = 0;
      this.selectedReason = false;
      this.showInfoReason1 = false;
      this.showInfoReason2 = false;
      this.reasonDetails1 = '';
      this.reasonDetails2 = '';
      this.reasonDetailsPlaceHolder1 = '';
      this.reasonDetailsPlaceHolder2 = '';
      this.replaceUnit = false;
      this.replaceECU = false;
      this.replaceSIM = false;
      this.selectedReason = false;
      this.newTagVisible = false;
      this.newImeiVisible = false;
      this.newSimVisible = false;
      this.oldTagVisible = false;
      this.oldImeiVisible = false;
      this.oldSimVisible = false;
      this.replacementReadonly = false;
      this.showServiceLogModal = true;
    },
    async removeServiceLogPhoto(photoId: string): Promise<void> {
      if (this.serviceLog) {
        const slId = this.serviceLog.id;
        await units.removeServiceLogPhoto(this.unitDetailsEntity.h2GenTag, slId, photoId);
        let index = this.serviceLogPhotos.findIndex(p => p.id === photoId);
        this.serviceLogPhotos.splice(index, 1);
        index = this.serviceLog.photos.findIndex(p => p === photoId);
        this.serviceLog.photos.splice(index, 1);
        await this.updateServiceLogPhotos();
      }
    },
    openServiceLogImage(p: ServiceLogPhoto): void {
      const image = new Image();
      image.src = p.data;
      const w = window.open();
      if (w) {
        w.document.write(image.outerHTML);
      }
    },

    addServiceLogPhoto(data: any): void {
      const reader = new FileReader();
      reader.onload = async (e) => {
        let imgData = e.target?.result as string;
        const base64Header = 'data:image/jpeg;base64,';
        imgData = imgData.replace(base64Header, '');
        if (this.serviceLog && this.serviceLog.id > 0) {
          const sl = this.serviceLog;
          const tag = this.unitDetailsEntity.h2GenTag;
          const p = await units.submitServiceLogPhoto(tag, sl.id, imgData);
          const slp = {
            id: p.id,
            data: imgData,
          } as ServiceLogPhoto;
          this.serviceLogPhotos.push(slp);
          if (!this.serviceLog.photos) {
            this.serviceLog.photos = [];
          }
          this.serviceLog.photos.push(p.id);
          await this.updateServiceLogPhotos();
        } else {
          const newId = ++this.lastPhotoId;
          const slp = {
            id: newId.toString(),
            data: imgData,
          } as ServiceLogPhoto;
          this.serviceLogPhotos.push(slp);
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },

    async createServiceLog() {
      try {
        if (this.serviceLog === null) {
          return;
        }
        const log = await units.createServiceLog(this.unitDetailsEntity.h2GenTag, this.serviceLog);
        const tag = this.unitDetailsEntity.h2GenTag;
        await this.serviceLogPhotos.reduce(async (previousPromise, slp) => {
          await previousPromise;

          try {
            const id = Number(log.id);
            await units.submitServiceLogPhoto(tag, id, slp.data);
          } catch (error) {
            console.error('API call error:', error);
          }
        }, Promise.resolve());
        log.timestamp = new Date(log.timestamp);
        this.serviceLogs.push(log);
        this.showServiceLogModal = false;

        await this.loadServiceLogs();
      } catch {
        // ignore for now
      }
    },
    async updateServiceLog() {
      try {
        if (this.serviceLog === null) {
          return;
        }
        const id = this.serviceLog.id;
        await units.updateServiceLog(this.unitDetailsEntity.h2GenTag, this.serviceLog);

        const index = this.serviceLogs.findIndex(t => t.id === id);
        this.serviceLogs.splice(index, 1);
        this.serviceLogs.push(this.serviceLog);
        this.showServiceLogModal = false;
        this.loadServiceLogs();
      } catch {
        // ignore for now
      }
    },
    async loadPhotos(): Promise<void> {
      this.isBusy = true;
      this.showButtons = false;
      const photos = await units.photos(this.unitDetailsEntity.h2GenTag);
      this.unitPhotos.splice(0, this.unitPhotos.length);
      if (photos.truckFront) {
        this.unitPhotos.push({
          title: 'Truck Front',
          img: photos.truckFront.startsWith('data:image') ? photos.truckFront : `data:image/jpeg;base64,${photos.truckFront}`,
        });
      }
      if (photos.truckBack) {
        this.unitPhotos.push({
          title: 'Geotab SN',
          img: photos.truckBack.startsWith('data:image') ? photos.truckBack : `data:image/jpeg;base64,${photos.truckBack}`,
        });
      }
      if (photos.unitMounting) {
        this.unitPhotos.push({
          title: 'Unit Mounting',
          img: photos.unitMounting.startsWith('data:image') ? photos.unitMounting : `data:image/jpeg;base64,${photos.unitMounting}`,
        });
      }
      if (photos.batteryConnections) {
        this.unitPhotos.push({
          title: 'Battery Connections',
          img: photos.batteryConnections.startsWith('data:image') ? photos.batteryConnections : `data:image/jpeg;base64,${photos.batteryConnections}`,
        });
      }
      if (photos.gasLineIntake) {
        this.unitPhotos.push({
          title: 'Gas Line Intake',
          img: photos.gasLineIntake.startsWith('data:image') ? photos.gasLineIntake : `data:image/jpeg;base64,${photos.gasLineIntake}`,
        });
      }
      this.isBusy = false;
    },
    async saveUnit(): Promise<void> {
      const unit = this.unitDetailsEntity;
      const installerData = {
        tag: unit.h2GenTag,
        isVehicle: true,
        manufacturer: unit.h2GenUnitInfo.manufacturer,
        year: unit.h2GenUnitInfo.year,
        vin: unit.h2GenUnitInfo.vin,
        odometer: unit.h2GenUnitInfo.odometer,
        applicationType: unit.h2GenUnitInfo.applicationType,
        engineManufacturer: unit.h2GenUnitInfo.engineManufacturer,
        engineModel: unit.h2GenUnitInfo.engineModel,
        engineSerialNumber: unit.h2GenUnitInfo.engineSerialNumber,
        engineDisplacement: unit.h2GenUnitInfo.engineDisplacement,
        customerFleetId: unit.h2GenUnitInfo.customerFleetId,
        comments: unit.h2GenUnitInfo.comments,
        location: unit.h2GenUnitInfo.installationLocation,
        date: unit.h2GenUnitInfo.installationDate,
        runSignalType: unit.h2GenUnitInfo.runSignalType,
        telematicsId: unit.telematicsId,
        simActivationProfileId: unit.h2GenICCIDActivationProfileId,
        customerId: unit.h2GenUnitInfo.customerId ? unit.h2GenUnitInfo.customerId : -1,
        startBaselineCalculation: false,
      };
      try {
        await units.update(installerData.tag, installerData);

        const dataUnit = this.items.find(u => u.h2GenUnitId === unit.h2GenUnitId);
        if (dataUnit) {
          dataUnit.h2GenUnitInfo = { ...unit.h2GenUnitInfo };
        }
      } catch {
        // ignore for now
      }
      this.unitDetailsModalVisible = false;
    },
    mapZoomUpdate(zoom: number) {
      this.map.currentZoom = zoom;
    },
    mapCenterUpdate(center: LatLng) {
      this.map.currentCenter = center;
    },
    async unitAnalyticsMonthNext(): Promise<void> {
      this.unitAnalyticsMonthOffset += 1;
      await this.fetchUnitAnalytics(this.unitDetailsEntity);
      // this.rend
    },
    async unitAnalyticsMonthPrevious(): Promise<void> {
      this.unitAnalyticsMonthOffset -= 1;
      await this.fetchUnitAnalytics(this.unitDetailsEntity);
    },
    customerChanged(customerId: string): void {
      const customer = this.customers.find(c => c.value === customerId);
      if (customer) {
        this.unitDetailsEntity.h2GenUnitInfo.customer = customer.org;
      }
    },
    reasonChanged(reasonId: any): void {
      if (this.serviceLog) {
        const reason = this.serviceLogReasons.find(c => c.value === reasonId);
        if (reason != null && this.serviceLog != null) {
          this.serviceLog.reason = reason.text;
          this.selectedReason = true;
          this.serviceLog.h2GenUnitReplacement = {
            serviceLogId: this.serviceLog.id,
            replacementType: 0,
            previousTag: this.unitDetailsEntity.h2GenTag,
            previousImei: this.unitDetailsEntity.h2GenIMEI,
            previousIccid: this.unitDetailsEntity.h2GenICCID,
            newImei: null,
            newIccid: null,
            newTag: null,
            simActivationProfileId: null,
          };
          this.showInfoReason1 = this.selectedReason;
          this.showInfoReason2 = this.selectedReason;

          switch (reason.value) {
            case 1:
              this.showInfoReason1 = true;
              this.showInfoReason2 = true;
              this.reasonDetailsPlaceHolder1 = 'Old IMEI';
              this.reasonDetailsPlaceHolder2 = 'New IMEI';
              this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousImei!;
              this.newSimVisible = false;
              // this.newImeiVisible = this.replaceECU;
              // this.serviceLog.h2GenUnitReplacement.newImei = this.reasonDetails;
              // this.serviceLog.h2GenUnitReplacement.previousImei
              // = this.unitDetailsEntity.h2GenIMEI;
              break;
            case 2:
              this.showInfoReason1 = true;
              this.showInfoReason2 = true;
              this.reasonDetailsPlaceHolder1 = 'Old SIM';
              this.reasonDetailsPlaceHolder2 = 'New SIM';
              this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousIccid!;
              this.newSimVisible = true;
              // this.serviceLog.h2GenUnitReplacement.newIccid = this.unitDetailsEntity.h2GenICCID;
              break;
            case 3:
              this.showInfoReason1 = true;
              this.showInfoReason2 = true;
              this.reasonDetailsPlaceHolder1 = 'Old Tag';
              this.reasonDetailsPlaceHolder2 = 'New Tag';
              this.reasonDetails1 = this.serviceLog.h2GenUnitReplacement.previousTag!;
              this.newSimVisible = false;
              // this.newTagVisible = this.replaceUnit;
              // this.serviceLog.h2GenUnitReplacement.newTag = this.reasonDetails;
              // this.serviceLog.h2GenUnitReplacement.previousTag = this.serviceLog.h2GenUnitTag;
              break;
            case 7:
              this.showInfoReason1 = false;
              this.showInfoReason2 = true;
              this.reasonDetailsPlaceHolder2 = 'Specify';
              this.newSimVisible = false;
              // this.serviceLog.reason = this.reasonDetails;
              // this.serviceLog.h2GenUnitReplacement = null;
              break;
            default:
              this.showInfoReason1 = false;
              this.showInfoReason2 = false;
              this.newSimVisible = false;
              this.serviceLog.h2GenUnitReplacement = null;
              break;
          }
        } else {
          this.showInfoReason1 = false;
          this.showInfoReason2 = false;
          this.newTagVisible = false;
          this.newSimVisible = false;
          this.newImeiVisible = false;
          this.serviceLog.h2GenUnitReplacement = null;
        }
      }
    },
    replaceChanged(newValue: string): void {
      if (this.serviceLog) {
        if (newValue != null && newValue !== '' && this.serviceLog != null) {
          // this.serviceLog.reason = newValue;
          switch (this.serviceLog.reasonId) {
            case 1:
              // this.showInfoReason = true;
              // this.reasonDetailsPlaceHolder = 'New IMEI';
              this.newImeiVisible = this.replaceECU;
              this.serviceLog.h2GenUnitReplacement!.newImei = newValue;
              this.serviceLog.h2GenUnitReplacement!.previousImei = this.unitDetailsEntity.h2GenIMEI;
              break;
            case 2:
              // this.showInfoReason = true;
              // this.reasonDetailsPlaceHolder = 'New SIM';
              this.newSimVisible = true;
              this.serviceLog.h2GenUnitReplacement!.newIccid = newValue;
              this.serviceLog.h2GenUnitReplacement!
                .previousIccid = this.unitDetailsEntity.h2GenICCID;
              break;
            case 3:
              // this.showInfoReason = true;
              // this.reasonDetailsPlaceHolder = 'New Tag';
              this.newTagVisible = this.replaceUnit;
              this.serviceLog.h2GenUnitReplacement!.newTag = newValue;
              this.serviceLog.h2GenUnitReplacement!.previousTag = this.serviceLog.h2GenUnitTag;
              break;
            case 7:
              // this.showInfoReason = true;
              // this.reasonDetailsPlaceHolder = 'Reason';
              this.serviceLog.reason = newValue;
              break;
            default:
              // this.showInfoReason = false;
              break;
          }
        } else {
          this.showInfoReason1 = false;
          this.showInfoReason2 = false;
          this.newTagVisible = false;
          this.newSimVisible = false;
          this.newImeiVisible = false;
          this.serviceLog.h2GenUnitReplacement = null;
        }
      }
    },
    // replaceChanged(): void {
    //   if (this.serviceLog) {
    //     if (this.replaceSIM || this.replaceECU || this.replaceUnit) {
    //       this.serviceLog.h2GenUnitReplacement = {
    //         serviceLogId: this.serviceLog.id,
    //         replacementType: 0,
    //         previousTag: null,
    //         previousImei: null,
    //         previousIccid: null,
    //         newImei: null,
    //         newIccid: null,
    //         newTag: null,
    //         simActivationProfileId: null,
    //       };
    //       this.showInfoReason = this.selectedReason;
    //       this.newTagVisible = this.replaceUnit;
    //       this.newSimVisible = this.replaceSIM;
    //       this.newImeiVisible = this.replaceECU;
    //     } else {
    //       this.showInfoReason = false;
    //       this.newTagVisible = false;
    //       this.newSimVisible = false;
    //       this.newImeiVisible = false;
    //       this.serviceLog.h2GenUnitReplacement = null;
    //     }
    //   }
    // }
    filterUnits(): void {
      if (this.unitSearch) {
        const search = this.unitSearch.toLowerCase();
        this.items.splice(0, this.items.length);
        this.allItems.sort((u1, u2) => u1.h2GenTag.localeCompare(u2.h2GenTag))
          .forEach((u: Unit) => {
            if (this.filterUnitsActive && !u.h2GenUnitInfo) {
              return;
            }
            if (u.h2GenTag && u.h2GenTag.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.h2GenModel && u.h2GenUnitInfo.h2GenModel.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.manufacturer && u.h2GenUnitInfo.manufacturer.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.vin && u.h2GenUnitInfo.vin.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.engineManufacturer && u.h2GenUnitInfo.engineManufacturer.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.customerFleetId && u.h2GenUnitInfo.customerFleetId.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenLastStatusCode && u.h2GenLastStatusCode.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
              // eslint-disable-next-line max-len
            } else if (u.h2GenLastStatusFullText && u.h2GenLastStatusFullText.toLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
            } else if (u.h2GenUnitInfo && u.h2GenUnitInfo.customer
            && u.h2GenUnitInfo.customer.name.toLocaleLowerCase()
              .includes(search)) {
              if (this.isUnitIncludedByStatus(u)) {
                this.items.push(u);
              }
            }
          });
      } else {
        this.items.splice(0, this.items.length);
        this.allItems.sort((u1, u2) => u1.h2GenTag.localeCompare(u2.h2GenTag))
          .forEach((u: Unit) => {
            if (this.filterUnitsActive && !u.h2GenUnitInfo) {
              return;
            }
            if (u.reactorData !== undefined && u.reactorData.receivedTime !== undefined) {
              u.dateLastTransmitted = moment(u.reactorData.receivedTime).format('YYYY-MM-DD');
            } else {
              u.dateLastTransmitted = '';
            }
            if (this.isUnitIncludedByStatus(u)) {
              this.items.push(u);
            }
          });
        this.items.sort((u1, u2) => -1 * u1.dateLastTransmitted
          .localeCompare(u2.dateLastTransmitted));

        const today = moment(new Date());
        const listUnits = this.items;
        if (listUnits) {
          for (let i = 0; i < listUnits.length; i++) {
            const unit = listUnits[i];
            if (unit.reactorData !== undefined && unit.reactorData.receivedTime !== undefined) {
              const dateLastTransmitted = moment(unit.reactorData.receivedTime);
              const dayDifference = today.diff(dateLastTransmitted, 'day');
              if (dayDifference > 60) {
                unit.h2GenLastStatusFullText = 'Inactive';
              }
            }
          }
        }
      }

      this.csv_data.splice(0, this.csv_data.length);
      if (this.items && this.items.length && this.items.length > 0) {
        this.items.forEach(i => {
          const unit = {
            tag: i.h2GenTag,
            statusCode: i.h2GenLastStatus,
            statusText: i.h2GenLastStatusFullText,
            model: i.h2GenUnitInfo ? i.h2GenUnitInfo.h2GenModel : '',
            manufacturer: i.h2GenUnitInfo ? i.h2GenUnitInfo.manufacturer : '',
            vin: i.h2GenUnitInfo ? i.h2GenUnitInfo.vin : '',
            engineManufacturer: i.h2GenUnitInfo ? i.h2GenUnitInfo.engineManufacturer : '',
            engineModel: i.h2GenUnitInfo ? i.h2GenUnitInfo.engineModel : '',
            customerFleetId: i.h2GenUnitInfo ? i.h2GenUnitInfo.customerFleetId : '',
            customer: i.h2GenUnitInfo && i.h2GenUnitInfo.customer
              ? i.h2GenUnitInfo.customer.name
              : '',
          };
          this.csv_data.push(unit);
        });

        this.csv_filename = `UNITS_Export_${this.$moment()
          .format('YYYYMMDD_HHmm')}.csv`;
      }
    },
    isUnitIncludedByStatus(unit: Unit): boolean {
      if (this.filterUnitStatus) {
        switch (this.filterUnitStatus) {
          case 'running':
            return unit.h2GenLastStatusCode === '00';
          case 'shutdown':
            return unit.h2GenLastStatusCode === '05'
              || unit.h2GenLastStatusCode === '07';
          case 'service':
            return unit.h2GenLastStatusCode === '03'
              || unit.h2GenLastStatusCode === '04'
              || unit.h2GenLastStatusCode === '06';
          case 'water':
            return unit.h2GenLastStatusCode === '01';
          case 'installed':
            return unit.h2GenLastStatusCode === '09';
          default:
            return false;
        }
      }
      return true;
    },
    filterUnitsByStatus(): void {
      this.filterUnits();
    },
    // replaceChanged(): void {
    //   if (this.serviceLog) {
    //     if (this.replaceSIM || this.replaceECU || this.replaceUnit) {
    //       this.serviceLog.h2GenUnitReplacement = {
    //         serviceLogId: this.serviceLog.id,
    //         replacementType: 0,
    //         previousTag: null,
    //         previousImei: null,
    //         previousIccid: null,
    //         newImei: null,
    //         newIccid: null,
    //         newTag: null,
    //         simActivationProfileId: null,
    //       };
    //       this.showInfoReason = this.selectedReason;
    //       this.newTagVisible = this.replaceUnit;
    //       this.newSimVisible = this.replaceSIM;
    //       this.newImeiVisible = this.replaceECU;
    //     } else {
    //       this.showInfoReason = false;
    //       this.newTagVisible = false;
    //       this.newSimVisible = false;
    //       this.newImeiVisible = false;
    //       this.serviceLog.h2GenUnitReplacement = null;
    //     }
    //   }
    // },
  },
  computed: {
    unitAnalyticsMonthNextAvailable(): boolean {
      return this.unitAnalyticsMonthOffset < 0;
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
