
import Vue from 'vue';
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import InstallationService from '@/services/installation';
import UnitsService from '@/services/units';
import {
  Organization,
  InstallerData,
  PhotosList,
  Unit,
} from '@/types';
import ProfileService from '@/services/profile';

export default Vue.extend({
  name: 'UnitInstallation',
  components: {
    Datepicker,
  },
  data() {
    return {
      isBusy: false,
      units: [] as Unit[],
      userCanSeeListOfUnits: false,
      manualSelect: '',
      customers: [] as Organization[],
      customersList: [] as any[],
      runSignalTypes: [] as string[],
      runSignalTypeManualEntryMode: false,
      simActivationProfiles: [] as any[],
      simActivationProfileDisabled: false,
      manufacturers: [] as string[],
      manufacturerManualEntryMode: false,
      engineManufacturers: [] as string[],
      engineManufacturerManualEntryMode: false,
      applicationTypes: [] as string[],
      applicationTypeManualEntryMode: false,
      unitsFields: [
        {
          label: 'Tag',
          key: 'h2GenTag',
          sortable: true,
        },
        {
          label: 'Select',
          key: 'selected',
          sortable: false,
        },
      ],
      selectedUnit: null as Unit | null,
      step1Finished: false,
      installerData: null as InstallerData | null,
      photos: null as PhotosList | null,
    };
  },
  computed: {
    step2Finished(): boolean {
      if (this.installerData
        && this.installerData.manufacturer
        && this.installerData.vin
        && this.installerData.odometer
        && this.installerData.applicationType
        && this.installerData.customerFleetId) {
        return true;
      }
      return false;
    },
    step3Finished(): boolean {
      if (this.installerData
        && this.installerData.location
        && this.installerData.date
        && this.installerData.runSignalType
        && this.installerData.simActivationProfileId) {
        return true;
      }
      return false;
    },
    step4Finished(): boolean {
      return this.step3Finished;
    },
    step5Finished(): boolean {
      if (this.installerData?.customerId) {
        return this.installerData?.customerId > 0;
      }
      return false;
    },
    saveButtonDisabled(): boolean {
      return !this.step5Finished;
    },
  },
  methods: {
    async init(): Promise<void> {
      try {
        this.isBusy = true;
        await this.loadUnitsForInstallation();
        await this.loadInstallerLists();
        const userType = ProfileService.getUserType();
        this.userCanSeeListOfUnits = userType != null && userType <= 20;
      } catch {
        // ignore
      } finally {
        this.isBusy = false;
      }
    },
    async loadUnitsForInstallation(): Promise<void> {
      const units = await InstallationService.getUnitsForInstallation();
      units.forEach(u => {
        // eslint-disable-next-line no-param-reassign
        u.selected = false;
        this.units.push(u);
      });
    },
    async loadInstallerLists(): Promise<void> {
      const c = await InstallationService.getCustomers();
      const d = await InstallationService.getDetailsLists();
      const i = await InstallationService.getInstallationLists();

      c.customers
        .filter(customer => customer.type === 40)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .forEach(customer => {
          this.customers.push(customer);
          this.customersList.push({
            value: customer.id,
            text: customer.name,
          });
        });
      d.engineManufacturers.forEach(em => {
        this.engineManufacturers.push(em);
      });
      d.manufacturers.forEach(m => {
        this.manufacturers.push(m);
      });
      d.applicationTypes.forEach(at => {
        this.applicationTypes.push(at);
      });
      i.runSignalTypes.forEach(rst => {
        this.runSignalTypes.push(rst);
      });
      i.simActivationProfiles.forEach((ap) => {
        this.simActivationProfiles.push({
          value: ap.activationProfileId,
          text: ap.activationProfileName,
        });
      });
    },
    async manuallySelectUnitNumber(): Promise<void> {
      try {
        this.isBusy = true;
        for (let i = 0; i < this.units.length; i += 1) {
          this.units[i].selected = false;
        }
        const unit = await UnitsService.readByTag(this.manualSelect);
        if (unit) {
          this.selectedUnit = unit;
          await this.prepareUnit();
        }
      } catch {
        // ignore
      } finally {
        this.isBusy = false;
      }
    },
    async selectUnit(unit: Unit): Promise<void> {
      try {
        this.isBusy = true;
        for (let i = 0; i < this.units.length; i += 1) {
          this.units[i].selected = unit.h2GenTag === this.units[i].h2GenTag;
        }
        this.selectedUnit = unit;
        await this.prepareUnit();
      } catch {
        // ignore
      } finally {
        this.isBusy = false;
      }
    },
    async prepareUnit(): Promise<void> {
      const unit = this.selectedUnit;
      if (unit) {
        this.step1Finished = false;
        this.installerData = await InstallationService.getInstallationData(unit.h2GenTag);
        if (!this.installerData.tag) {
          this.installerData.tag = unit.h2GenTag;
          this.step1Finished = true;
        }
        if (unit.h2GenICCIDActivationProfileId) {
          this.simActivationProfileDisabled = true;
          this.installerData.simActivationProfileId = this.simActivationProfiles[0].value;
        }

        const installerDataObj = moment(this.installerData.date);
        const installerDataString = installerDataObj.format('YYYY-MM-DD');
        if (installerDataString === '0001-01-01') {
          this.installerData.date = new Date();
        }

        const base64Header = 'data:image/jpeg;base64,';
        const photos = await InstallationService.getPhotos(unit.h2GenTag);
        if (!photos.truckFront) {
          photos.truckFront = '';
        } else {
          photos.truckFront = `${base64Header}${photos.truckFront}`;
        }
        if (!photos.truckBack) {
          photos.truckBack = '';
        } else {
          photos.truckBack = `${base64Header}${photos.truckBack}`;
        }
        if (!photos.unitMounting) {
          photos.unitMounting = '';
        } else {
          photos.unitMounting = `${base64Header}${photos.unitMounting}`;
        }
        if (!photos.batteryConnections) {
          photos.batteryConnections = '';
        } else {
          photos.batteryConnections = `${base64Header}${photos.batteryConnections}`;
        }
        if (!photos.gasLineIntake) {
          photos.gasLineIntake = '';
        } else {
          photos.gasLineIntake = `${base64Header}${photos.gasLineIntake}`;
        }
        this.photos = photos;
      }
    },
    unselectUnit(unit: Unit): void {
      unit.selected = false;
      this.selectedUnit = null;
    },
    step1SetAsVehicle(): void {
      if (this.installerData) {
        this.installerData.isVehicle = true;
        this.step1Finished = true;
      }
    },
    step1SetAsStationary(): void {
      if (this.installerData) {
        this.installerData.isVehicle = false;
        this.step1Finished = true;
      }
    },
    addPhotoTruckFront(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.photos) {
          this.photos.truckFront = e.target?.result as string;
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    addPhotoTruckBack(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.photos) {
          this.photos.truckBack = e.target?.result as string;
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    addPhotoUnitMounting(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.photos) {
          this.photos.unitMounting = e.target?.result as string;
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    addPhotoBatteryConnections(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.photos) {
          this.photos.batteryConnections = e.target?.result as string;
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    addPhotoGasLineIntake(data: any): void {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (this.photos) {
          this.photos.gasLineIntake = e.target?.result as string;
        }
      };
      reader.readAsDataURL(data.target.files[0]);
    },
    async saveUnit(): Promise<void> {
      if (this.installerData) {
        const { tag } = this.installerData;
        await InstallationService.saveInstallationData(tag, this.installerData);

        const base64Header = 'data:image/jpeg;base64,';
        if (this.photos && this.photos.truckFront) {
          const photo = this.photos.truckFront.replace(base64Header, '');
          await InstallationService.addPhoto(tag, 'TRUCK_FRONT', photo);
        } else {
          await InstallationService.deletePhoto(tag, 'TRUCK_FRONT');
        }
        if (this.photos && this.photos.truckBack) {
          const photo = this.photos.truckBack.replace(base64Header, '');
          await InstallationService.addPhoto(tag, 'TRUCK_BACK', photo);
        } else {
          await InstallationService.deletePhoto(tag, 'TRUCK_BACK');
        }
        if (this.photos && this.photos.unitMounting) {
          const photo = this.photos.unitMounting.replace(base64Header, '');
          await InstallationService.addPhoto(tag, 'UNIT_MOUNTING', photo);
        } else {
          await InstallationService.deletePhoto(tag, 'UNIT_MOUNTING');
        }
        if (this.photos && this.photos.batteryConnections) {
          const photo = this.photos.batteryConnections.replace(base64Header, '');
          await InstallationService.addPhoto(tag, 'BATTERY_CONNECTIONS', photo);
        } else {
          await InstallationService.deletePhoto(tag, 'BATTERY_CONNECTIONS');
        }
        if (this.photos && this.photos.gasLineIntake) {
          const photo = this.photos.gasLineIntake.replace(base64Header, '');
          await InstallationService.addPhoto(tag, 'GAS_LINE_INTAKE', photo);
        } else {
          await InstallationService.deletePhoto(tag, 'GAS_LINE_INTAKE');
        }
      }
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
